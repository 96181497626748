/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    pre: "pre",
    code: "code",
    a: "a",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "Running a full stack application locally can be tricky and awkward. In this article we show you how to get a Static Web App talking to an Azure Function locally, using the SWA CLI. The SWA CLI is a local developer tool for Azure Static Web Apps and some things it can do are:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Serve static app assets, or proxy to your app dev server"), "\n", React.createElement(_components.li, null, "Serve API requests, or proxy to APIs running in Azure Functions Core Tools"), "\n", React.createElement(_components.li, null, "Emulate authentication and authorization"), "\n", React.createElement(_components.li, null, "Emulate Static Web Apps configuration, including routing and ACL roles"), "\n", React.createElement(_components.li, null, "Deploy your app to Azure Static Web Apps"), "\n"), "\n", React.createElement(_components.p, null, "Once setup, all you need to type is:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "swa start ./client --api-location ./api\n")), "\n", React.createElement(_components.p, null, "This article is based off this video:\n", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=TIh52zbi8Dk"
  }, "Serverless Full-Stack Kickstart with Davide Mauri"), "."), "\n", React.createElement(_components.h2, null, "Prerequisites"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Npm"), "\n", React.createElement(_components.li, null, "Homebrew"), "\n", React.createElement(_components.li, null, "Azure Cli (to deploy)"), "\n"), "\n", React.createElement(_components.h2, null, "Recommended"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Node v16.x"), "\n", React.createElement(_components.li, null, "Azure Function v4.x\n", React.createElement(_components.a, {
    href: "https://docs.microsoft.com/en-au/azure/azure-functions/functions-reference-node?tabs=v2-v3-v4-export%2Cv2-v3-v4-done%2Cv2%2Cv2-log-custom-telemetry%2Cv2-accessing-request-and-response%2Cwindows-setting-the-node-version#node-version"
  }, "Azure Functions Versions")), "\n"), "\n", React.createElement(_components.h2, null, "Install"), "\n", React.createElement(_components.h3, null, "Functions Cli"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "brew tap azure/functions\nbrew install azure-functions-core-tools@4\n# if upgrading on a machine that has 2.x or 3.x installed:\nbrew link --overwrite azure-functions-core-tools@4\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://docs.microsoft.com/en-us/azure/azure-functions/functions-run-local?tabs=v4%2Cmacos%2Ccsharp%2Cportal%2Cbash"
  }, "Azure Functions Core Tools")), "\n", React.createElement(_components.h3, null, "Static Web App (SWA) Cli"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install -g @azure/static-web-apps-cli\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://azure.github.io/static-web-apps-cli/docs/use/install/"
  }, "https://azure.github.io/static-web-apps-cli/docs/use/install/")), "\n", React.createElement(_components.h2, null, "Host SWA Locally with Cli"), "\n", React.createElement(_components.h3, null, "Create Directories"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "mkdir swa-test && cd swa-test\nmkdir client && mkdir api \n")), "\n", React.createElement(_components.h3, null, "Create Basic Web Client"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "cd client && curl https://raw.githubusercontent.com/vuejs/vuejs.org/master/src/v2/examples/vue-20-todomvc/index.html -o index.html\n")), "\n", React.createElement(_components.h3, null, "Serve Web Client Locally"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash",
    title: "swa-test/client/"
  }, "cd ..\nswa start client\n")), "\n", React.createElement(_components.p, null, "Go to the localhost address to see your basic todo website running e.g. ", React.createElement(_components.a, {
    href: "http://localhost:4280"
  }, "http://localhost:4280")), "\n", React.createElement(_components.h2, null, "Connect to Api"), "\n", React.createElement(_components.h3, null, "Create Azure Function"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash",
    title: "swa-test/api/"
  }, "cd api\nfunc init --worker-runtime dotnet\nfunc new --name ToDo --template HttpTrigger\n")), "\n", React.createElement(_components.h3, null, "Run Client & Api"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash",
    title: "swa-test/"
  }, "cd ..\nswa start ./client --api-location ./api\n")), "\n", React.createElement(_components.p, null, "Both the SWA and Azure Function should start running locally and you can view them on localhost."), "\n", React.createElement(_components.p, null, "To test the Azure Function use Postman (or an alternative) with"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl http://localhost:7071/api/todo?name=test\n")), "\n", React.createElement(_components.p, null, "You should get a response similar to:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "Hello, test. This HTTP triggered function executed successfully.\n")), "\n", React.createElement(_components.p, null, "However, the client is not talking to the api yet so we need to make some changes."), "\n", React.createElement(_components.h3, null, "Update Client"), "\n", React.createElement(_components.p, null, "If you manage to follow the code below you’ll have the client and api talking via the GET method and it will load in the ToDo when the client starts up."), "\n", React.createElement(_components.p, null, "If you want to add the full Add, Edit, Delete, Update functionality (or just want to skip the code below) have a look at the full code at the repo:\n", React.createElement(_components.a, {
    href: "https://github.com/tombrereton/swa-cli"
  }, "https://github.com/tombrereton/swa-cli")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diff",
    title: "swa-test/client/index.html"
  }, "<script>\n+      API = \"api/todo\";\n+      HEADERS = {'Accept': 'application/json', 'Content-Type': 'application/json'};\n\n-      var todoStorage = {\n-        fetch: function() {\n-          var todos = JSON.parse(localStorage.getItem(STORAGE_KEY) || \"[]\");\n-          todos.forEach(function(todo, index) {\n-            todo.id = index;\n-          });\n-          todoStorage.uid = todos.length;\n-          return todos;\n-        },\n-        save: function(todos) {\n-         localStorage.setItem(STORAGE_KEY, JSON.stringify(todos));\n-        }\n-      };\n\n       var app = new Vue({\n         // app initial state\n         data: {\n-          todos: todoStorage.fetch(),\n+          todos: [],\n           newTodo: \"\",\n           editedTodo: null,\n           visibility: \"all\"\n         },\n\n+      created: function() {\n+        fetch(API, {headers: HEADERS, method: \"GET\"})\n+        .then(res => {\n+          return res.json();\n+        })\n+        .then(res => {\n+          this.todos = res == null ?  [] : res;\n+        })\n+      },\n\n-        // watch todos change for localStorage persistence\n-        watch: {\n-          todos: {\n-           handler: function(todos) {\n-              todoStorage.save(todos);\n-            },\n-            deep: true\n-          }\n-        },\n\n</script>\n\n")), "\n", React.createElement(_components.h3, null, "Update Api"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-cs",
    title: "swa-test/api/ToDo.cs"
  }, "public static class ToDoHandler\n{\n  static List<ToDo> _db = new List<ToDo>();\n\tstatic int _nextId = 1;\n\n  static ToDoHandler()\n  {\n    _db.Add(new ToDo { Id = 1, Title = \"Hello from Azure Function!\", Completed = true });\n    _db.Add(new ToDo { Id = 2, Title = \"Hello, from the other side\", Completed = false });\n  }\n\n  [FunctionName(\"Get\")]\n  public static async Task<IActionResult> Run([HttpTrigger(AuthorizationLevel.Anonymous, \"get\", Route = \"todo/{id:int?}\")] \n\tHttpRequest req, ILogger log, int? id)\n  {\n   if (id == null)\n     return new OkObjectResult(_db);\n   \n\t var todoTask = _db.Find(i => i.Id == id);\n\n   if (todoTask == null)\n     return await Task.FromResult<IActionResult>(new NotFoundResult());\n\n   return await Task.FromResult<IActionResult>(new OkObjectResult(todoTask));\n  }\n}\n\ninternal class ToDo\n{\n  public int Id { get; internal set; }\n  public string Title { get; internal set; }\n  public bool Completed { get; internal set; }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
